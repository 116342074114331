import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import { REGION_LIST } from "@/game-lol/constants/constants.mjs";
import {
  getDerivedIdFromSummoner,
  isDerivedRiotID,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import { setOverlaySettings } from "@/game-tft/actions.mjs";
import { defaultSettings } from "@/game-tft/InGameOverlayStreamInfoExternal.jsx";
import { Slider } from "@/game-tft/InGameSettingsModal.jsx";
import fetchStreamerOverlayData from "@/game-tft/tft-fetch-streamer-overlay-data.mjs";
import ShareIcon from "@/inline-assets/share.svg";
import debounce from "@/util/debounce.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function setRiotId(name) {
  const searchParams = new URLSearchParams(router.route.searchParams);
  const riotId = name.replace("#", "-");
  try {
    if (!isDerivedRiotID(riotId)) return;
    searchParams.set("summoner", riotId);
    updateRoute(router.route.currentPath, searchParams, router.route.state);
  } catch (_) {
    //
  }
}

function setRegion(region) {
  const searchParams = new URLSearchParams(router.route.searchParams);
  searchParams.set("region", region);
  updateRoute(router.route.currentPath, searchParams, router.route.state);
}

function setSettings(settings, overlaySettings) {
  // save settings
  if (overlaySettings && Object.keys(overlaySettings).length) {
    setOverlaySettings({
      ...overlaySettings,
      streamInfoOverlay: settings,
    });
  }

  const searchParams = new URLSearchParams(router.route.searchParams);
  for (const key in defaultSettings) {
    if (settings?.[key] !== undefined && settings?.[key] !== null) {
      if (typeof defaultSettings[key] === "object") {
        if (settings?.[key]?.value !== defaultSettings[key].value) {
          searchParams.set(key, settings?.[key]?.value);
        } else {
          searchParams.delete(key);
        }
      } else if (settings?.[key] !== defaultSettings[key]) {
        searchParams.set(key, settings?.[key]);
      } else {
        searchParams.delete(key);
      }
    }
  }
  updateRoute(router.route.currentPath, searchParams, router.route.state);
}

const Copypaste = styled("div")`
  max-width: 30rem;
  background: var(--shade9);
  padding: var(--sp-2) var(--sp-4);
  border-radius: var(--br);
  display: flex;
  justify-content: space-between;

  code {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 3em);
  }
`;

const IconContainer = styled("span")`
  cursor: pointer;
`;

const translations = {
  size: ["tft:benchmarking.size", "Size"],
  opacity: ["tft:benchmarking.opacity", "Opacity"],
  smallRankIcon: ["tft:benchmarking.smallRankIcon", "Small Rank Icon"],
  placements: ["tft:placements", "Placements"],
  avgPlace: ["tft.avgPlace", "Avg Place"],
  gamesFromHours: ["tft:benchmarking.gamesFromHours", "Games From"],
  hyperRollDoubleUp: [
    "tft:benchmarking.hyperRollDoubleUp",
    "Hyperroll / Double Up Rank",
  ],
  queue: ["tft:common.gameMode", "Game Mode"],
};

const VALID_SLIDERS = ["size", "opacity", "gamesFromHours"];
const gameModes = [
  { value: 1100, text: ["tft:rankedTft", "Ranked TFT"] },
  { value: 1130, text: ["tft:hyperroll", "Hyperroll"] },
  { value: 1150, text: ["tft:doubleUp", "Double Up"] },
];

const DIMENSIONS_PADDING = 20;

export default [
  (): React.ReactNode => {
    const { t } = useTranslation();
    useEffect(() => {
      blitzMessage(EVENTS.GET_TFT_OVERLAY_SETTINGS);
    }, []);

    const overlaySettingsData = readState.tft.overlaysInGame;
    let overlaySettings = readState.settings.tft.overlays;
    if (overlaySettingsData && Object.keys(overlaySettingsData).length)
      overlaySettings = overlaySettingsData;

    const [updatedSettings, setUpdatedSettings] = useState(defaultSettings);
    handleMessage(EVENTS.TFT_OVERLAY_SETTINGS, (data) => {
      if (typeof data === "object" && "streamInfoOverlay" in data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setUpdatedSettings(data.streamInfoOverlay as any);
      }
    });
    const subToggleSwitches =
      updatedSettings && Object.entries(updatedSettings);

    const onChange = (key) => (value) => {
      if (VALID_SLIDERS.includes(key)) {
        const newSettings = {
          ...updatedSettings,
          [key]: { ...updatedSettings[key], value: value },
        };
        setUpdatedSettings(newSettings);
        setSettings(newSettings, overlaySettings);
      } else {
        const newSettings = {
          ...updatedSettings,
          [key]: value,
        };
        setUpdatedSettings(newSettings);
        setSettings(newSettings, overlaySettings);
      }
    };

    return subToggleSwitches?.length
      ? subToggleSwitches.map(([key, value], idx) => {
          if (key === "size") return "";
          const translation = translations[key];
          if (!translation) return null;
          if (typeof value === "object") {
            return (
              <div key={idx} className="flex column gap-1">
                <div className="title">{t(...translation)}</div>
                <Slider
                  min={value.min}
                  max={value.max}
                  step={value.step}
                  defaultValue={value.value}
                  onChange={onChange(key)}
                  isPercentile={key === "gamesFromHours"}
                  textSuffix={
                    key === "gamesFromHours" ? t("common:hours", "h") : ""
                  }
                />
              </div>
            );
          }
          return (
            <div
              key={idx}
              className="flex justify-between gap-2 align-center wrap"
            >
              <div className="title">{t(...translation)}</div>
              {key === "queue" || typeof value === "number" ? (
                <ButtonGroup block className={modeBtns()}>
                  {gameModes.map((mode) => (
                    <Button
                      onClick={() => onChange(key)(mode.value)}
                      active={updatedSettings.queue === mode.value}
                      key={mode.value}
                    >
                      {t(mode.text)}
                    </Button>
                  ))}
                </ButtonGroup>
              ) : (
                <ToggleSwitch value={value} onChange={onChange(key)} />
              )}
            </div>
          );
        })
      : null;
  },
  (): React.ReactNode => {
    const { searchParams } = useRoute();
    const { t } = useTranslation();

    const currentSummoner =
      readState.volatile?.currentSummoner?.toLowerCase() ||
      getDerivedIdFromSummoner(
        readState.settings.loggedInAccounts.lol[
          readState.settings.lastLoggedInIdByGame.lol
        ],
      ) ||
      "na1:souless-001";
    const regionFromParam =
      searchParams.get("region") &&
      REGION_LIST.find((r) => r.name === searchParams.get("region"))?.key;
    const regionFromSettings =
      readState.settings?.riotRegion || currentSummoner?.split(":")[0];
    const region = regionFromParam || regionFromSettings;
    const regionName = REGION_LIST.find((r) => r.key === region)?.name;
    const nameFromParam = searchParams.get("summoner")?.replace("#", "-");
    const nameData = nameFromParam || currentSummoner?.split(":")[1];
    const [gameName, tagLine] = isDerivedRiotID(nameData)
      ? separateDerivedRiotID(nameData)
      : [];

    const {
      volatile: { streamerDimensions },
    } = useSnapshot(readState);

    const dimensions = streamerDimensions
      ? t("common:dimensions", "Dimensions: {{width}} x {{height}}", {
          width: Math.ceil(streamerDimensions.w + DIMENSIONS_PADDING),
          height: Math.ceil(streamerDimensions.h + DIMENSIONS_PADDING),
        })
      : null;

    const url = `${globals.location.origin}/tft/overlay/stream?${searchParams}`;

    const copyLink = useCallback(() => {
      globals.navigator.clipboard.writeText(url);
    }, [url]);

    const regions = REGION_LIST.filter((val) => val.key !== "world").map(
      (v) => {
        return {
          value: v.key,
          text: [v.t.name, v.t.fallback],
        };
      },
    );

    useEffect(() => {
      if (!regionFromParam && regionName) {
        setRegion(regionName);
      }
      if (!nameFromParam && nameData) {
        setRiotId(nameData);
      }
    });

    useEffect(() => {
      fetchStreamerOverlayData({}, searchParams);
    }, [searchParams]);

    return (
      <>
        {gameName && tagLine ? (
          <>
            <div className="type-body1-form--active">
              {t("common:overlayURL", "Overlay URL")}
            </div>
            <p className="type-body2 shade1">
              {t(
                "common:overlayURLDescription",
                "Copypaste this into your streaming app (in OBS Studio: Sources → Browser).",
              )}
            </p>
            <Copypaste className="type-body2 shade1">
              <code>{url}</code>
              <IconContainer
                onClick={copyLink}
                data-tooltip={t(
                  "common:share.copied",
                  "Web link copied to clipboard!",
                )}
                data-event="click"
              >
                <ShareIcon width="20" />
              </IconContainer>
            </Copypaste>
            <p className="type-body2 shade4">{dimensions}</p>
          </>
        ) : (
          <div className="type-body2 shade1">
            {t(
              "tft:enterRiotIdForLink",
              `Enter your Riot ID to get external link for streaming apps
              such as OBS Studio.`,
            )}
          </div>
        )}
        <div className="type-body1-form--active">
          {t("common:accountInfo", "Account Info")}
        </div>
        <ButtonGroup block className={`flex wrap ${regionBtns()}`}>
          {regions.map((reg, i) => (
            <Button
              key={i}
              onClick={() => {
                setRegion(REGION_LIST.find((r) => r.key === reg.value)?.name);
              }}
              active={region === reg.value}
            >
              {t(reg.text)}
            </Button>
          ))}
        </ButtonGroup>
        <div className="flex" style={{ gap: "var(--sp-3)" }}>
          <TextInput
            defaultValue={`${gameName}#${tagLine}`}
            placeholder={"Riot ID"}
            onValueChange={debounce(setRiotId, 1000)}
          />
        </div>
      </>
    );
  },
];

const regionBtns = () => css`
  button {
    flex: 0 !important;
  }
`;

const modeBtns = () => css`
  @container (inline-size >= 500px) {
    max-width: 70%;
  }
`;
